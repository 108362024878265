<template>
  <div class="jh-container">
    <div class="jh-ui-header">
      <h1>스크립트 변경 관리</h1>
      <div class="is-right">
      </div>
    </div>
    <div class="jh-search-form">
      <table>
        <colgroup>
          <col width="55px">
          <col width="250px">
          <col width="70px">
          <col width="100px">
          <col width="70px">
          <col width="100px">
          <col width="85px">
          <col width="300px">
          <col>
        </colgroup>
        <tr>
          <!-- <th><label>조회기간</label></th>
          <td>
            <div class="jh-cols">                        
              <v-menu v-model="menuStartDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="from" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuStartDate = false"
                  maxlength="10" @input="fromOnInput" @click:clear="dates[0] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                </template>
                <v-date-picker class="jh-calendar" v-model="dates[0]" @input="menuStartDate = false" no-title locale="ko-KR" :max="dates[1]" :day-format="mixin_getDate" @change="startDate"></v-date-picker>
              </v-menu>
              <span class="jh-unit">~</span>                       
              <v-menu v-model="menuEndDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="to" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuEndDate = false"
                  maxlength="10" @input="toOnInput" @click:clear="dates[1] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                </template>
                <v-date-picker class="jh-calendar" v-model="dates[1]" @input="menuEndDate = false" no-title locale="ko-KR" :min="dates[0]" :day-format="mixin_getDate" @change="endDate"></v-date-picker>
              </v-menu>
            </div>
          </td> -->
          <th><label>분류구분</label></th>
          <td>
            <v-select class="jh-form" v-model="searchCode" :items="this.mixin_common_code_get(this.commonCode, 'SYS002', '전체')"></v-select>
          </td>
          <th><label>변경상태</label></th>
          <td>
            <v-select class="jh-form" v-model="CHNG_REQ" :items="this.mixin_common_code_get(this.commonCode, 'SCP002', '전체')"></v-select>
          </td>
          <th><label>스크립트명</label></th>
          <td>
            <v-text-field class="jh-form" placeholder="스크립트명" v-model="searchCont" @keyup.enter="getList(searchCont)"></v-text-field>
          </td>
          <td class="has-search"><v-btn class="jh-btn is-search" @click="getList">조회</v-btn></td>
        </tr>
      </table>
    </div>

    <div class="jh-form-wrap">
      <div class="jh-ui-header">
        <h2>스크립트 변경리스트</h2>
        <div class="is-right">
          <v-btn class="jh-btn is-del" v-if="this.mixin_set_btn(this.$options.name, 'btnDeny')" @click="showAlertDel">요청거부</v-btn>
          <v-btn class="jh-btn is-main" v-if="this.mixin_set_btn(this.$options.name, 'btnAccess')" @click="showAlertReg">승인</v-btn>
        </div>
      </div>
        <v-data-table
          
          height="320px"
          :headers="gridDataHeaders"
          :items="gridDataText"
          item-key="ROW_NUMBER"
          hide-default-footer
          class="jh-grid has-control"
          fixed-header
          :items-per-page="30"
          :page.sync="page"
          calculate-widths
          @page-count="pageCount = $event"
          @click:row="tableClick"
          show-select
          :item-class="isActiveRow"
          v-model="CHK_SCP"
          
        >
          <template v-slot:item.REG_DTIM="{ item }" >
            <div>{{ dateFormat(item.REG_DTIM) }}</div>
          </template>
        </v-data-table>
            <div class="jh-pager">
              <v-pagination v-model="page" :length="pageCount" :total-visible="totalVisible"></v-pagination>
              <span class="jh-counter is-left">총 <em>{{ gridDataText.length }}</em> 건</span>
            </div> 
    </div>

    <div class="jh-form-wrap jh-cols is-pt-10">
      <div>
        <div class="jh-ui-header">
          <h2>변경 전 스크립트내용</h2>
        </div>
        <v-textarea class="jh-form is-bg-white" style="height: 200px;" v-model="CHNG_BF_CNTN" disabled></v-textarea>
      </div>
      <div>
        <div class="jh-ui-header">
          <h2>변경 후 스크립트내용</h2>
        </div>
        <v-textarea class="jh-form is-bg-white" style="height: 200px;" v-model="CHNG_AF_CNTN" disabled></v-textarea>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/store/apiUtil.js';
import { mapState, mapGetters, mapActions } from "vuex";
import { mixin } from "@/mixin/mixin.js";
import dataTables from "@/components/DataTables";

export default {
  mixins: [mixin],
  name: "MENU_E020611", //name은 'MENU_' + 파일명 조합
  components: {dataTables},
  data() {
    return {
      aspCustKey: "001",
      searchCont: "",
      searchCode: "",
      CHNG_BF_CNTN: "",
      CHNG_AF_CNTN: "",

      selectedRow: [],

      menuStartDate: false,
      menuEndDate: false,
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],
      gridDataHeaders: [
        // { text: "checkBox", value: "data-table-select", align: "center", width: "30px", sortable: false},
        { text: "No", value: "ROWNUM", align: "center", width: "60px", sortable: false},
        // { text: '회사명', value: 'ASP_NEWCUST_KEY', align: 'center', },
        { text: "변경일시", value: "REG_DTTM", align: "center", width: "160px" },
        { text: '변경상담사', value: 'REQ_USER_NM', align: 'center', width: "100px"},
        { text: '스크립트분류명', value: 'LVL_NO_NM', align: 'center', width: "120px"},
        // { text: '사용구분', value: 'USE_TY_NM', align: 'center', width: "80px"},
        { text: '변경전 스크립트명', value: 'CHNG_BF_TIT', },
        { text: '변경후 스크립트명', value: 'CHNG_AF_TIT', },
        { text: '스크립트변경이력번호', value: 'CHNG_HIST_NO', align: 'center', width: "150px" },
        { text: '스크립트 변경상태', value: 'CHNG_REQ_NM', align: 'center', width: "140px" },
      ],
      totalVisible: 10,

      commonCode: [],
      commonCodeArr: ["SYS002","SCP002"],

      gridDataText: [],
      page: 1,
      pageCount: 0,
      // itemsPerPage: 10,
      // totalVisible: 10,      
      dataTableOptions: {
        fixedHeader: true,
        hideDefaultFooter: true,
        height: '330px',
        itemKey: 'ROW_NUMBER',
        itemsPerPage: 50,
        noDataText: "조회된 결과가 없습니다.",
        page: 1,
        showSelect: true,
        itemClass: "isActiveRow"
      },
      paginationOptions: {
        totalVisible: 10
      },

      SCP_NO:'',
      SEARCH_USE_TY:[
        {CD:"", CD_NM:"전체"}
      ],
      SEARCH_USE_NEWTY:"CHAT",

      CHK_SCP:[],
      CHNG_REQ:'',
    };
  },
  async mounted() {
    this.commonCode = await this.mixin_common_code_get_all(this.commonCodeArr);
    let codeName = ["SCP001"];
    let common_code = await this.mixin_common_code_get_all(codeName);
    this.SEARCH_USE_TY = [...this.SEARCH_USE_TY, ...common_code[0].group_value];
  },
  methods: {
    fromOnInput: function() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}-\d{2}$/.test(this.from) && moment(this.from).isValid()) {
        // 종료일자보다 이후를 입력했으면 종료일자로 변경
        if (moment(this.from).isAfter(this.dates[1])) {
          this.$nextTick(() => {
            this.from = this.dates[1];
          });
        }

        this.$nextTick(() => {
          this.dates[0] = this.from;
          // datepick 다시 랜더링
          this.menuStartDate = false;
          this.menuStartDate = true;
        });
      }
    },
    startDate(e) {
      this.from = e;
    },
    toOnInput: function() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}-\d{2}$/.test(this.to) && moment(this.to).isValid()) {
        // 시작일자보다 이전을 입력했으면 시작일자로 변경
        if (moment(this.to).isBefore(this.dates[0])) {
          this.$nextTick(() => {
            this.to = this.dates[0];
          });
        }

        this.$nextTick(() => {
          this.dates[1] = this.to;
          // datepick 다시 랜더링
          this.menuEndDate = false;
          this.menuEndDate = true;
        });
      }
    },
    endDate(e) {
      this.to = e;
    },
    gridSelected(){

    },
    async getList(){
      if(this.aspCustKey == '' || this.aspCustKey == null){
        this.common_alert('회사구분을 선택해주세요.', 'error');
        return;
      }

      // 변경 스크립트 리스트 조회
      let listUrl = "/api/phone/script/change-manage/list";
      let requestData = {
        headers: {},
        sendData:{}
      };

      requestData.headers["URL"] = listUrl;
      requestData.headers["SERVICE"] = "phone.script.change-manage.list";
      requestData.headers["METHOD"] = "list";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = true;
      requestData.headers["PAGES_CNT"] = 1;
      requestData.headers["ROW_CNT"] = 100;

      //sendData 초기화
      requestData.sendData["ASP_NEWCUST_KEY"] = this.aspCustKey; //회사코드
      // requestData.sendData["START_DATE"] = this.dates[0]; //시작일
      // requestData.sendData["END_DATE"] = this.dates[1]; //종료일
      requestData.sendData["CNSL_DIV"] = this.searchCode; //분류명
      requestData.sendData["CHNG_REQ"] = this.CHNG_REQ; //검색어
      requestData.sendData["SEARCH_CONT"] = this.searchCont; //검색어
      // requestData.sendData["USE_TY"] = this.SEARCH_USE_NEWTY;  //사용구분


      let response = await this.common_postCall(requestData);
      this.setList(response);
      //console.log(response);
    },
    setList(response){
      let header = response.HEADER;
      let data = response.DATA;

      // 정상조회
      if(!header.ERROR_FLAG){
        this.gridDataText = data;
        this.CHNG_BF_CNTN = "";
        this.CHNG_AF_CNTN = "";

        //선택된 row 초기화
        if(this.selectedRow != ""){
          this.activeRow(this.selectedRow,false);
          this.selectedRow = "";
        }

      } else {
        this.gridDataText = [];
      }
    },
    tableClick(value, row){
      this.CHNG_BF_CNTN = row.item.CHNG_BF_CNTN;
      this.CHNG_AF_CNTN = row.item.CHNG_AF_CNTN;

      this.SCP_NO = row.item.CHNG_HIST_NO;

    },

    dateFormat(date){
      //날짜 포멧
      if(!date) return "";
      var newDate = "";

      //공백제거
      date = date.replace(/\s/gi, "");
      newDate = date.replace( /(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1/$2/$3 $4:$5:$6');

      return newDate;

    },

    isActiveRow(item) {
      const activeClass =
        item.CHNG_HIST_NO === this.SCP_NO ? "is-active" : "";
      return activeClass;
    },

    showAlertReg(){
      if(this.CHK_SCP.length == 0){
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: "체크된 항목이 존재하지 않습니다.",
          iconClass: "svg-error-lg",
          type: "default",
        });
        return;
      }
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: '변경요청을 완료하시겠습니까?',
        iconClass: 'svg-chk-lg',
        type: 'confirm',
        callYes: this.callYes1,
        callNo: this.callNo1,
      })
    },

    callYes1() {
      this.$store.commit("alertStore/openAlertDialog", {
      alertDialogToggle: false,
      });

      this.finReq('SUCCESS');
    },

    showAlertDel(){
      if(this.CHK_SCP.length == 0){
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: "체크된 항목이 존재하지 않습니다.",
          iconClass: "svg-error-lg",
          type: "default",
        });
        return;
      }
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: '요청을 거부하시겠습니까?',
        iconClass: 'svg-error-lg',
        type: 'confirm',
        callYes: this.callYes2,
        callNo: this.callNo2,
      })
    },
    
    callYes2() {
      this.$store.commit("alertStore/openAlertDialog", {
      alertDialogToggle: false,
      });
      this.finReq('CANT');
    },

    callNo2(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },

    finReq(req){
      let REQ_COUNT=0;
      for(let n=0;n<this.CHK_SCP.length;n++){
        if (this.CHK_SCP[n].CHNG_REQ == 'SUCCESS' || this.CHK_SCP[n].CHNG_REQ == 'CANT'){
          REQ_COUNT = REQ_COUNT+1;
        }
      }
      if(REQ_COUNT != "0"){
        this.common_alert("이미 변경이 완료된 스크립트가 있습니다.<br>변경이 완료되지 않은 스크립트를 선택해주세요.","error");
        return false;
      }
      for(let i=0;i<this.CHK_SCP.length;i++){
        api.post("api/phone/script/manage/fin-request",
        {
          ASP_NEWCUST_KEY: this.aspCustKey,
          SCRIPT_ID: this.CHK_SCP[i].SCRIPT_ID,
          SCRIPT_TIT: this.CHK_SCP[i].CHNG_AF_TIT,
          SCRIPT_RMK: this.CHK_SCP[i].CHNG_AF_CNTN,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          BEGIN_DATE: this.CHK_SCP[i].BEGIN_DATE,
          EOT_DATE: this.CHK_SCP[i].EOT_DATE,
          CHNG_REQ: req,
          CHNG_HIST_NO: this.CHK_SCP[i].CHNG_HIST_NO,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
        },
        {head: {
          "SERVICE" : "phone.script.manage.fin-request",
          "METHOD" : "fin-request",
          "TYPE" : "BIZ_SERVICE",
        }})
        .then((response) => {
          this.success();
          this.getList();
          this.SCP_NO = '';
          this.CHNG_REQ = '';
          this.CHK_SCP=[];
        })
        .catch((err) => {
          alert(err);
        })
      }
    },
    
    success(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: '정상처리 되었습니다.',
        iconClass: 'svg-done-lg',
        type: 'default'
      })
    },
  },
  computed: {
    dateRangeText() {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + " ~ " + newEndDate;
    },
    ...mapGetters({
      //store에 저장된 상담사의 company 정보를 가져온다
      aspCustList: "userStore/GE_USER_COMPANY",
    }),
  },
  created() { 
    this.dataTableOptions.headers = this.gridDataHeaders;
    this.dataTableOptions.items = this.gridDataText;
  }
};
</script>

<style></style>
